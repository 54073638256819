
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import { mdiPlus } from '@mdi/js'
import { cloneDeep } from 'lodash-es'

import CommonTableView from '@/components/common/CommonTableView.vue'

import { useNotify } from '@/store'

import { useFilter } from '@/composables/useFilter'

import { setSort } from '@/utils/manipulateTableSort'

import { useDeleteWorkPlace, useGetWorkPlaces } from '@/api/workPlace'

import { WorkPlace } from '@/api/types/workPlace'
import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'WorkPlaces',
  components: {
    CommonTableView,
    AddEditWorkPlaceDialog: () => import('@/views/baseData/views/workPlaces/components/AddEditWorkPlaceDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
  },
  setup: (_, { root }) => {
    const TABLE_HEADERS = [
      {
        text: root.$t('baseData.workPlaces.table.col.title.objectName'),
        value: 'objectName',
        sortable: true,
      },
      {
        text: root.$t('baseData.workPlaces.table.col.title.level'),
        value: 'level',
        sortable: true,
      },
      {
        text: root.$t('baseData.workPlaces.table.col.title.department'),
        value: 'department',
        sortable: true,
      },
      {
        text: root.$t('baseData.workPlaces.table.col.title.address1'),
        value: 'address1',
        sortable: true,
      },
      {
        text: root.$t('baseData.workPlaces.table.col.title.address2'),
        value: 'address2',
        sortable: true,
      },
      {
        text: root.$t('baseData.workPlaces.table.col.title.addressZipCode'),
        value: 'addressZipCode',
        sortable: true,
      },
      {
        text: root.$t('baseData.workPlaces.table.col.title.addressCity'),
        value: 'addressCity',
        sortable: true,
      },
      {
        text: root.$t('baseData.workPlaces.table.col.title.addressCountry'),
        value: 'addressCountry',
        sortable: true,
      },
      {
        text: root.$t('baseData.workPlaces.table.col.title.actions'),
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const { addNotification } = useNotify()

    const { debouncedCb, vuetifyTableOptions, paginationParams } = useFilter([], init)
    setSort(vuetifyTableOptions, { by: 'objectName', desc: false })

    const { exec: getWorkPlaces, data: workPlaces, isLoading, paginationResponse } = useGetWorkPlaces()

    const totalWorkPlaces = computed(() => paginationResponse.value.totalElements)

    async function init(): Promise<void> {
      await getWorkPlaces({ params: { ...paginationParams.value } })
    }

    const isEditMode = ref(false)

    const isAddEditModalOpen = ref(false)

    const workPlaceToEdit = ref<null | WorkPlace>(null)

    function onWorkPlaceEdit(_, { item: workPlace }: { item: WorkPlace }): void {
      if (hasSufficientRights(Rights.WORK_PLACE_UPDATE)) {
        isEditMode.value = true

        isAddEditModalOpen.value = true

        workPlaceToEdit.value = { ...workPlace }
      }
    }

    const isDeleteModalOpen = ref(false)
    const workPlaceToDelete = ref<null | WorkPlace>(null)

    const { deleteWorkPlace: deleteWorkPlaceXhr } = useDeleteWorkPlace()

    async function deleteWorkPlace(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await deleteWorkPlaceXhr(workPlaceToDelete.value!.id)

      addNotification({
        text: root.$t('baseData.workPlaces.delete.message') as string,
        type: 'success',
      })

      isDeleteModalOpen.value = false

      init()
    }

    function onWorkPlaceDelete(workPlace: WorkPlace): void {
      workPlaceToDelete.value = cloneDeep(workPlace)

      isDeleteModalOpen.value = true
    }

    function onCloseAddEditDialog(): void {
      isAddEditModalOpen.value = false

      workPlaceToEdit.value = null

      isEditMode.value = false
    }

    return reactive({
      icons: {
        mdiPlus,
      },
      constants: {
        Rights,

        TABLE_HEADERS,
      },
      state: {
        workPlaces,
        isLoading,
        totalWorkPlaces,

        isDeleteModalOpen,

        isEditMode,
        isAddEditModalOpen,
        workPlaceToEdit,

        vuetifyTableOptions,
      },
      functions: {
        deleteWorkPlace,

        debouncedCb,

        hasSufficientRights,
      },
      listeners: {
        onCloseAddEditDialog,
        onWorkPlaceEdit,
        onWorkPlaceDelete,
      },
    })
  },
})
