var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonTableView', {
    attrs: {
      "headers": _vm.constants.TABLE_HEADERS,
      "items": _vm.state.workPlaces,
      "loading": _vm.state.isLoading,
      "with-info": false,
      "options": _vm.state.vuetifyTableOptions,
      "server-items-length": _vm.state.totalWorkPlaces
    },
    on: {
      "update:options": [function ($event) {
        return _vm.$set(_vm.state, "vuetifyTableOptions", $event);
      }, function ($event) {
        return _vm.functions.debouncedCb();
      }],
      "dblclick:row": _vm.listeners.onWorkPlaceEdit,
      "click:delete": function clickDelete($event) {
        return _vm.listeners.onWorkPlaceDelete($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "table-header",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "my-4 ma-0",
          attrs: {
            "justify": "end",
            "align": "center"
          }
        }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.WORK_PLACE_CREATE) ? _c('v-btn', {
          staticClass: "ml-2 rounded-lg",
          attrs: {
            "color": "primary",
            "elevation": "0"
          },
          on: {
            "click": function click($event) {
              _vm.state.isAddEditModalOpen = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('baseData.workPlaces.actions.addWorkPlace')) + " ")], 1) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "item.actions.delete",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.WORK_PLACE_DELETE) ? _c('div') : _vm._e()];
      },
      proxy: true
    }], null, true)
  }, [_vm.state.isAddEditModalOpen ? _c('AddEditWorkPlaceDialog', {
    attrs: {
      "is-edit-mode": _vm.state.isEditMode,
      "work-place-to-edit": _vm.state.workPlaceToEdit
    },
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.functions.debouncedCb();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditModalOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditModalOpen", $$v);
      },
      expression: "state.isAddEditModalOpen"
    }
  }) : _vm._e(), _c('CommonDeleteDialog', {
    on: {
      "cancel": function cancel($event) {
        _vm.state.isDeleteModalOpen = false;
      },
      "delete": function _delete($event) {
        return _vm.functions.deleteWorkPlace();
      }
    },
    model: {
      value: _vm.state.isDeleteModalOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteModalOpen", $$v);
      },
      expression: "state.isDeleteModalOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('baseData.workPlaces.deleteWorkPlace.text')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }