import { BasicEntity } from '@/api/types/misc'
import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'
import { PromiseType } from '@/utils/types/PromiseType'

import {
  UseGetWorkPlaces,
  UseGetWorkPlacesBasic,
  UseCreateWorkPlace,
  UseDeleteWorkPlace,
  UseUpdateWorkPlace,
  NewWorkPlace,
  WorkPlace,
  WorkPlaceId,
} from './types/workPlace'

const VERSION = 'v1'
const RESOURCE = 'work-places'

function useGetWorkPlaces(): UseGetWorkPlaces {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetWorkPlaces['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetWorkPlacesBasic(): UseGetWorkPlacesBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateWorkPlace(): UseCreateWorkPlace {
  const axios = useAxios<PromiseType<ReturnType<UseCreateWorkPlace['createWorkPlace']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createWorkPlace(data: NewWorkPlace): Promise<WorkPlace> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createWorkPlace,
  }
}

function useUpdateWorkPlace(): UseUpdateWorkPlace {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateWorkPlace['updateWorkPlace']>>>({ method: 'PUT' })

  function updateWorkPlace(id: WorkPlaceId, data: WorkPlace): Promise<WorkPlace> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateWorkPlace,
  }
}

function useDeleteWorkPlace(): UseDeleteWorkPlace {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteWorkPlace['deleteWorkPlace']>>>({ method: 'DELETE' })

  function deleteWorkPlace(id: WorkPlaceId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteWorkPlace,
  }
}

export { useGetWorkPlaces, useGetWorkPlacesBasic, useCreateWorkPlace, useUpdateWorkPlace, useDeleteWorkPlace }
